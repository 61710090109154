<template>
  <div class="app-container">
    <router-view />
  </div>
</template>

<script>
import { watch } from 'vue';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const { isAuthenticated } = useAuth0();

    watch(isAuthenticated, (newVal) => {
      if (newVal) {
        store.dispatch('getAccount');
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/app2.scss";

.app-container {
  height: 100%;
}
</style>
